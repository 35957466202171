import React, { useContext } from "react";
import { QuestionSection, RadioRange } from "../../components/utility/quote";
import { navigate } from "gatsby-link";
import InternetContext from "../../contexts/internetContext";
import { Asap, Within1, Within12, Within3, Within6 } from "../../svgs/asap";

const Next = "/internet/Questions/3";
const Points = [
  {
    question: "Install timeframe required?",
    progress: "50%",
    options: [
      {
        answer: "Within 1 month",
        to: Next,
        icon: <Within1 />,
      },
      { answer: "Within 3 months", to: Next, icon: <Within3 /> },
      { answer: "Within 6 months", to: Next, icon: <Within6 /> },
      { answer: "Within 12 months", to: Next, icon: <Within12 /> },
      { answer: "As soon as possible", to: Next, icon: <Asap /> },
    ],
  },
];
export default function Question2({ title }) {
  const { que2, setQue2 } = useContext(InternetContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <RadioRange
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que2 === items.answer}
              onChange={(e) => {
                setQue2(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
